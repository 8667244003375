import React, { Fragment, PureComponent } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { InView } from 'react-intersection-observer'

import Button, { PRIMARY } from '@components/button'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import { track } from 'analytics'
import AddressDisplay from 'components/address/display'
import Logo from 'components/logo'
import { white } from 'components/style-helpers/global-variables'
import { SplitContext } from 'context/split'
import ROUTES from 'helpers/routes'
import Colors from 'microcomponents/colors'

import { TEST_IDS } from './test/constants'

import PropTypes from 'prop-types'

const AddressModal = dynamic(import('components/address'), {
  loading: () => null,
  ssr: false
})

const Sidebar = dynamic(import('@components/sidebar'), {
  loading: () => null,
  ssr: false
})

const SearchDrawer = dynamic(import('components/search'), {
  loading: () => null,
  ssr: false
})

if (typeof window !== 'undefined') {
  require('intersection-observer') // needed for safari
}

export default class Navbar extends PureComponent {
  static propTypes = {
    backButtonRoute: PropTypes.string,
    cartCount: PropTypes.number,
    goToCartDrawer: PropTypes.func,
    hasBanner: PropTypes.bool,
    hideAddress: PropTypes.bool,
    hideLogoAtTop: PropTypes.bool,
    hideSidebarButton: PropTypes.bool,
    hydrated: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    noCheckout: PropTypes.bool,
    router: PropTypes.object,
    showAddressOnMobile: PropTypes.bool,
    showBackButton: PropTypes.bool,
    showBottomBorder: PropTypes.bool,
    showLoginShopButtons: PropTypes.bool,
    showLogoInMiddle: PropTypes.bool,
    showNewCart: PropTypes.bool,
    showScrollEffect: PropTypes.bool,
    showSearchButton: PropTypes.bool,
    showSupportButton: PropTypes.bool,
    toggleAddress: PropTypes.func,
    toggleSidebar: PropTypes.func,
    toggleSearchDrawer: PropTypes.func
  }

  static defaultProps = {
    hideAddress: false,
    hideLogoAtTop: false,
    hideSidebarButton: false,
    showAddressOnMobile: false,
    showBackButton: false,
    showScrollEffect: false,
    showSupportButton: false,
    backButtonRoute: ROUTES.ROOT,
    showLoginShopButtons: false,
    showLogoInMiddle: false,
    showNewCart: false
  }

  state = {
    atTopOfViewport: true
  }

  // REMOVE after SEARCH experiment
  static contextType = SplitContext

  componentDidMount() {
    if (!this.props.showScrollEffect) {
      this.setState({ atTopOfViewport: false })
    }
  }

  handleBackButton = () => {
    const { backButtonRoute, router } = this.props
    router.push(backButtonRoute)
  }

  renderLoginShopButtons = (showCart) => {
    const { hydrated, isLoggedIn, showLoginShopButtons } = this.props
    const { atTopOfViewport } = this.state

    if (showLoginShopButtons) {
      return (
        <Fragment>
          {!isLoggedIn && hydrated && (
            <Link href={ROUTES.LOGIN}>
              <a data-e2eid={TEST_IDS.LOGIN_LINK}>
                <Login atTopOfViewport={atTopOfViewport} showCart={showCart}>
                  Log In
                </Login>
              </a>
            </Link>
          )}

          <Link href={ROUTES.MENU}>
            <a>
              <Shop atTopOfViewport={atTopOfViewport} showCart={showCart}>
                Shop
              </Shop>
            </a>
          </Link>
        </Fragment>
      )
    }

    return null
  }

  renderViewCart = (showCart) => {
    const { cartCount, goToCartDrawer, showNewCart, noCheckout } = this.props
    const { atTopOfViewport } = this.state

    const renderPillCart = (
      <PillCart invert={!atTopOfViewport} onClick={goToCartDrawer} show={showCart}>
        <PillCartIcon /> {cartCount || ''}
      </PillCart>
    )

    const renderButtonCart = (
      <ButtonCart onClick={goToCartDrawer} show={showCart}>
        <Button data-e2eid={TEST_IDS.OPEN_CART_BUTTON} notification={cartCount || ''} buttonType={PRIMARY}>
          View Cart <CartIcon src="/static/icons/cart-full.svg" />
        </Button>
      </ButtonCart>
    )

    if (noCheckout) return null
    return showNewCart ? renderPillCart : renderButtonCart
  }

  renderAddressContainer = (color) => {
    const { hideAddress, showAddressOnMobile, showLogoInMiddle, toggleAddress } = this.props
    return (
      !hideAddress && (
        <AddressDisplayContainer showAddressOnMobile={showAddressOnMobile} showLogoInMiddle={showLogoInMiddle}>
          {showLogoInMiddle && <LocationPin color={color} />}
          <LocationButton color={color} onClick={toggleAddress} />
        </AddressDisplayContainer>
      )
    )
  }

  handleSearchButtonClick = () => {
    this.props.toggleSearchDrawer()
    track('Search.View')
  }

  inSearchExperiment = () => {
    return this.context[SPLIT_EXPERIMENTS.CLIENT_SIDE_SEARCH]?.treatment === SPLIT_TREATMENTS.CLIENT_SIDE_SEARCH.ON
  }

  render() {
    const {
      cartCount,
      hasBanner,
      hideLogoAtTop,
      hideSidebarButton,
      showBackButton,
      showBottomBorder,
      showLogoInMiddle,
      showScrollEffect,
      showSearchButton,
      showSupportButton,
      toggleSidebar
    } = this.props
    const { atTopOfViewport } = this.state

    const hasNotScrolled = atTopOfViewport
    const color = hasNotScrolled && showScrollEffect ? 'white' : 'black'
    const showCart = cartCount !== 0
    const showSearchExperiment = showSearchButton && this.inSearchExperiment()
    const { router } = this.props
    // check if path includes ROUTES.ORDER_STATUS
    const isOrdersPath = router?.pathname?.includes(ROUTES.ORDER_STATUS)

    return (
      <Fragment>
        {showScrollEffect && (
          <InView initialInView onChange={(inView) => this.setState({ atTopOfViewport: inView })}>
            <ScrollMeasure />
          </InView>
        )}
        <NavbarSpacerDiv showScrollEffect={showScrollEffect}>
          <Nav
            data-e2eid={TEST_IDS.COMPONENT}
            hasBanner={hasBanner}
            hasNotScrolled={hasNotScrolled}
            showBottomBorder={showBottomBorder}
            showScrollEffect={showScrollEffect}>
            <Container>
              {!hideSidebarButton && (
                <SidebarButton
                  data-e2eid={showBackButton ? TEST_IDS.BACK_BUTTON : TEST_IDS.TOGGLE_SIDEBAR_BUTTON}
                  onClick={showBackButton ? this.handleBackButton : toggleSidebar}>
                  {showBackButton ? <LeftArrow color={color} /> : <Burger color={color} />}
                </SidebarButton>
              )}

              {this.renderAddressContainer(color)}

              {showLogoInMiddle && !isOrdersPath && (
                <Link href={ROUTES.ROOT}>
                  <a>
                    {/* set scale to 0.81 because 0.8 causes some bad sub-pixel math that crops the logo */}
                    <StyledLogo
                      hideLogoAtTop={hideLogoAtTop}
                      atTopOfViewport={atTopOfViewport}
                      color={atTopOfViewport ? 'white' : '#00A6F0'}
                      scale={0.68}
                    />
                  </a>
                </Link>
              )}
              {showLogoInMiddle && isOrdersPath && (
                <StyledLogo
                  hideLogoAtTop={hideLogoAtTop}
                  atTopOfViewport={atTopOfViewport}
                  color={atTopOfViewport ? 'white' : '#00A6F0'}
                  scale={0.68}
                />
              )}

              <ButtonGroup>
                {showSearchExperiment && (
                  <SearchButton
                    data-e2eid={TEST_IDS.SEARCH_BUTTON}
                    onClick={this.handleSearchButtonClick}
                    showCart={showCart}>
                    <SearchButtonIcon />
                  </SearchButton>
                )}

                {this.renderLoginShopButtons(showCart)}

                {this.renderViewCart(showCart)}
              </ButtonGroup>

              {showSupportButton && (
                <HelpButton
                  href="https://eaze.zendesk.com/hc/en-us/requests/new"
                  rel="noopener noreferrer"
                  target="_blank">
                  <picture>
                    <source srcSet="/static/icons/question-mark.svg" type="image/svg" />
                    <img src="/static/icons/question-mark.svg" alt="" />
                  </picture>
                </HelpButton>
              )}
            </Container>
          </Nav>
        </NavbarSpacerDiv>
        <Sidebar />
        <AddressModal />
        {showSearchExperiment && <SearchDrawer />}
      </Fragment>
    )
  }
}

const ScrollMeasure = styled.div`
  position: absolute;
  top: 0px;
`

const LeftArrow = (props) => (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g
      transform="translate(15.500000, 15.000000) scale(-1, 1) translate(-15.500000, -15.000000) translate(5.000000, 6.000000)"
      stroke={props.color}
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round">
      <path d="M12 0l9 9M0 9h21m0 0l-9 8.485" />
    </g>
  </svg>
)

LeftArrow.propTypes = {
  color: PropTypes.string
}

const Burger = (props) => (
  <svg width="20px" height="14px" viewBox="0 0 16 14" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g transform="translate(-21.000000, -23.000000)">
      <g
        id="Menu"
        transform="translate(21.000000, 23.000000)"
        strokeWidth="1.5"
        stroke={props.color}
        strokeLinecap="square"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="square">
        <line x1="0.769230769" y1="1" x2="19.2307692" y2="1" id="Line" />
        <line x1="0.769230769" y1="7" x2="19.2307692" y2="7" id="Line-Copy" />
        <line x1="0.769230769" y1="13" x2="19.2307692" y2="13" id="Line-Copy-2" />
      </g>
    </g>
  </svg>
)

Burger.propTypes = {
  color: PropTypes.string
}

const HelpButton = styled.a`
  position: absolute;
  width: 3rem;
  right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledLogo = styled(Logo, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'atTopOfViewport' && prop !== 'hideLogoAtTop'
})`
  opacity: ${({ atTopOfViewport, hideLogoAtTop }) => (atTopOfViewport && hideLogoAtTop ? 0 : 1)};
  @media (max-width: 767px) {
    opacity: 1;
  }
`

// NavbarSpacerDiv MUST have a height exactly EQUAL to element w/ position: fixed (Nav) to push content down
// For pages that use showScrollEffect we don't want to push content down bc of transparent navbar
const NavbarSpacerDiv = styled.div`
  height: ${({ showScrollEffect }) => (showScrollEffect ? 0 : 6)}rem;
`

const Nav = styled.nav`
  width: 100%;
  height: 6rem;
  position: fixed;
  top: ${(props) => (props.hasBanner ? 4.8 : 0)}rem;
  left: 0;
  z-index: 10;
  background: ${({ hasNotScrolled, showScrollEffect }) =>
    hasNotScrolled && showScrollEffect ? 'transparent' : 'white'};
  border-bottom: ${({ showBottomBorder }) => (showBottomBorder ? ' 1px solid rgba(235, 236, 237, 1)' : 'none')};
  transition: all 0.2s;

  /* CSS specific to iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    border-bottom: none;
    background-color: ${({ hasNotScrolled, showScrollEffect }) =>
      hasNotScrolled && showScrollEffect ? 'transparent' : 'rgba(255, 255, 255, 1)'} !important;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  height: 100%;
  position: relative;
`

const SidebarButton = styled.button`
  border: 0px;
  cursor: pointer;
  color: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
  color: black !important;
  background-color: transparent;
`

const LocationPin = ({ color }) => (
  <svg width="12px" height="18px" viewBox="0 0 12 18" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M6 16.634a88.262 88.262 0 0 0 2.349-3.855c.349-.611.675-1.205.974-1.775C10.55 8.666 11.25 6.842 11.25 5.87 11.25 3.046 8.903.75 6 .75 3.096.75.75 3.046.75 5.87c0 .972.7 2.796 1.927 5.134.3.57.625 1.164.975 1.775A83.078 83.078 0 0 0 6 16.634zM7.75 6a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0z"
      stroke={color}
      strokeWidth="1.5"
      fill="none"
    />
  </svg>
)

LocationPin.propTypes = {
  color: PropTypes.string
}

const LocationButton = styled(AddressDisplay)`
  font-size: 1.2rem;
  color: ${({ color }) => color};
`

const AddressDisplayContainer = styled.div`
  display: flex;
  ${({ showLogoInMiddle }) => (showLogoInMiddle ? 'left: 4.5rem; position: absolute' : '')};

  @media (max-width: 767px) {
    ${({ showAddressOnMobile }) => (showAddressOnMobile ? '' : 'display: none;')};
  }
`

const ButtonGroup = styled.div`
  position: absolute;
  right: 0;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
`

const Shop = styled.div`
  font-family: Aventa;
  line-height: 1;
  border: 0px;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  padding: 0.75rem 1.15rem 0.825rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: translateX 0.2s, opacity 0.2s;
  transform: translateX(${({ showCart }) => (showCart ? -1 : 0)}rem);
  color: ${({ atTopOfViewport }) => (atTopOfViewport ? Colors.rebrand[1] : 'white')};
  background-color: ${({ atTopOfViewport }) => (atTopOfViewport ? 'white' : Colors.rebrand[1])};

  & svg {
    margin-right: 1rem;
  }

  @media (max-width: 767px) {
    display: ${({ showCart }) => (showCart ? 'none' : 'block')};
    color: white;
    background-color: ${Colors.rebrand[1]};
  }
`

const ButtonCart = styled.div`
  position: absolute;
  right: 1rem;
  display: flex;
  margin-right: 0.4rem;
  transform: translateX(${({ show }) => (show ? 0 : 12)}rem);
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

  @media (max-width: 500px) {
    display: none;
  }

  & svg {
    margin-right: 1rem;
  }
`

const CartIcon = styled.img`
  margin-left: 1rem;
  height: 1rem;
`

const PillCart = styled.button`
  border: 0px;
  cursor: pointer;
  outline: none;
  border-radius: 2rem;
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  flex-shrink: 0;
  color: ${({ invert }) => (invert ? 'white' : '#00b4ea')};
  background-color: ${({ invert }) => (invert ? '#00b4ea' : 'white')};
  transition: all 0.2s;
  & svg {
    margin-right: 1rem;
  }
`

const PillCartIcon = (props) => (
  <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M.003.603c-.037.404.247.677.694.696l2.708.126.428 1.807c.012.144.012.1.026.149l1.866 6.804a.797.797 0 0 0 .047.127l.03.058.024.039c.02.03.03.042.04.055.02.027.032.039.043.051.021.023.033.033.044.044.023.022.034.03.045.038.028.023.038.03.048.036.034.023.045.029.055.035l.057.03c.037.017.047.02.058.024.042.016.054.019.065.022.092.024.15.024.218.024h10.764c.365 0 .685-.202.781-.552l1.926-6.835a.799.799 0 0 0-.136-.702c-.154-.201-.39-.286-.645-.286H5.044L4.643.729a.81.81 0 0 0-.748-.592L.785.002C.755 0 .628.002.697 0 .266.011.04.2.003.603zM5.322 3.59h13.3l-1.775 5.982-9.835.092-1.69-6.074zm3.227 7.179c1.23 0 2.23.994 2.23 2.217a2.227 2.227 0 0 1-2.23 2.217 2.225 2.225 0 0 1-2.23-2.217c0-1.223.999-2.217 2.23-2.217zm6.76 0c1.23 0 2.23.994 2.23 2.217a2.227 2.227 0 0 1-2.23 2.217 2.225 2.225 0 0 1-2.23-2.217c0-1.223.999-2.217 2.23-2.217zm-6.75 1.319a.946.946 0 0 0-.947.942.948.948 0 1 0 1.896 0 .948.948 0 0 0-.948-.942zm6.76 0a.946.946 0 0 0-.947.942.948.948 0 0 0 1.896 0 .948.948 0 0 0-.949-.942z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

const Login = styled.div`
  font-family: 'Suisse Intl Book', system-ui, sans-serif;
  font-weight: 700;
  border: 0px;
  cursor: pointer;
  outline: none;
  border-radius: 2rem;
  padding: 0.75rem 1.15rem 0.825rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  transition: translateX 0.2s;
  transform: translateX(${({ showCart }) => (showCart ? -1 : 0)}rem);
  color: ${({ atTopOfViewport }) => (atTopOfViewport ? 'white' : Colors.rebrand[1])} !important;
  background-color: transparent;

  @media (max-width: 767px) {
    display: none;
  }
`

const SearchButton = styled.button`
  border: 0;
  border-radius: 2rem;
  cursor: pointer;
  color: ${white};
  font-size: 1.2rem;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: transparent;
  transition: all 0.25s ease-in-out;
  ${({ showCart }) => (showCart ? 'position: absolute; right: 12.5rem;' : '')}
  & svg {
    margin-top: 0.5rem;
    margin-right: 2rem;
  }

  @media (max-width: 767px) {
    padding: 0.5rem;
    ${({ showCart }) => (showCart ? 'right: 14.5rem;' : '')}
    & svg {
      margin: 0.5rem 0 0 1rem;
    }
  }

  @media (max-width: 500px) {
    position: static;
    right: auto;
  }
`

const SearchButtonIcon = (props) => (
  <svg version="1.1" width="20px" height="20px" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848S326.847,409.323,225.474,409.323z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
)
